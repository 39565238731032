import React, { useEffect } from "react";

export default function Component() {
  const baseUrl =
    "https://app.edba.io/set-password.html";
  useEffect(() => {
    window.location.href = `${baseUrl}?${window.location.search}`;
  }, []);
  return "Redirecting..."
}
